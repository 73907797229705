var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "elwfecyetv41",
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
        "shape-rendering": "geometricPrecision",
        "text-rendering": "geometricPrecision"
      }
    },
    [
      _c(
        "g",
        { attrs: { id: "elwfecyetv42_to", transform: "translate(0,0)" } },
        [
          _c("path", {
            attrs: {
              id: "elwfecyetv42",
              d: _vm.drawingCommands1,
              transform: "translate(0,0)",
              fill: "rgb(0,188,135)",
              stroke: "none",
              "stroke-width": "1"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "g",
        { attrs: { id: "elwfecyetv43_to", transform: "translate(0,0)" } },
        [
          _c("path", {
            attrs: {
              id: "elwfecyetv43",
              d: _vm.drawingCommands2,
              transform: "translate(0,0)",
              fill: "rgb(0,188,135)",
              stroke: "none",
              "stroke-width": "1"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "g",
        { attrs: { id: "elwfecyetv44_to", transform: "translate(0,0)" } },
        [
          _c("path", {
            attrs: {
              id: "elwfecyetv44",
              d: _vm.drawingCommands3,
              transform: "translate(0,0)",
              fill: "rgb(0,188,135)",
              stroke: "none",
              "stroke-width": "1"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "elwfecyetv45_ts",
            transform: "translate(23.999850,22.817950) scale(1,1)"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "elwfecyetv45",
              d: _vm.drawingCommands4,
              transform: "translate(-23.999850,-22.817950)",
              fill: "rgb(0,188,135)",
              stroke: "none",
              "stroke-width": "1"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }