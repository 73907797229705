var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Icon",
    _vm._b({ style: _vm.iconTransform }, "Icon", _vm.$attrs, false),
    [
      _c("rect", {
        attrs: { width: "2", height: "12", transform: "matrix(-1 0 0 1 8 6)" }
      }),
      _vm._v(" "),
      _c("path", { attrs: { d: _vm.drawingCommands } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }