var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Icon",
    _vm._b({ style: _vm.iconTransform }, "Icon", _vm.$attrs, false),
    [_c("polygon", { attrs: { points: _vm.points } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }