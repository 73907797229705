var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Icon",
    _vm._b(
      { style: { transform: _vm.arrowStyle.transform } },
      "Icon",
      _vm.$attrs,
      false
    ),
    [
      _c("path", {
        attrs: { d: _vm.lowerArrow.drawingCommands, fill: _vm.arrowStyle.color }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: _vm.upperArrow.drawingCommands,
          fill: _vm.upperArrow.fillColor
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }