var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: _vm.viewBox,
        width: _vm.width,
        height: _vm.height,
        "aria-label": _vm.iconName,
        role: "presentation"
      }
    },
    [_c("g", { attrs: { fill: _vm.fillColor } }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }