/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
<template>
    <Icon v-bind="$attrs">
        <path :d="drawingCommands" />
    </Icon>
</template>

<script>

export default {
    name: 'IconSettings',
    inheritAttrs: false,
    computed: {
        drawingCommands() {
            return 'M18.1126 11.216C18.1455 11.472 18.1701 11.728 18.1701 12C18.1701 12.272 18.1455 12.528 18.1126 12.784L19.8479 14.104C20.0042 14.224 20.0453 14.44 19.9466 14.616L18.3017 17.384C18.203 17.56 17.9892 17.632 17.8 17.56L15.7522 16.76C15.3245 17.072 14.8639 17.344 14.3622 17.544L14.0497 19.664C14.025 19.856 13.8523 20 13.6467 20H10.3569C10.1513 20 9.97861 19.856 9.95393 19.664L9.64141 17.544C9.13972 17.344 8.67915 17.08 8.25148 16.76L6.2036 17.56C6.02266 17.624 5.8006 17.56 5.70191 17.384L4.05702 14.616C3.95833 14.44 3.99945 14.224 4.15571 14.104L5.89107 12.784C5.85817 12.528 5.8335 12.264 5.8335 12C5.8335 11.736 5.85817 11.472 5.89107 11.216L4.15571 9.896C3.99945 9.776 3.9501 9.56 4.05702 9.384L5.70191 6.616C5.8006 6.44 6.01443 6.368 6.2036 6.44L8.25148 7.24C8.67915 6.928 9.13972 6.656 9.64141 6.456L9.95393 4.336C9.97861 4.144 10.1513 4 10.3569 4H13.6467C13.8523 4 14.025 4.144 14.0497 4.336L14.3622 6.456C14.8639 6.656 15.3245 6.92 15.7522 7.24L17.8 6.44C17.981 6.376 18.203 6.44 18.3017 6.616L19.9466 9.384C20.0453 9.56 20.0042 9.776 19.8479 9.896L18.1126 11.216ZM9 12C9 13.6543 10.3457 15 12 15C13.6543 15 15 13.6543 15 12C15 10.3457 13.6543 9 12 9C10.3457 9 9 10.3457 9 12Z';
        },
    },
};
</script>
