var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Icon",
    _vm._b({ style: _vm.iconTransform }, "Icon", _vm.$attrs, false),
    [
      _c("circle", {
        attrs: {
          cx: _vm.radius,
          cy: _vm.radius,
          r: _vm.normalizedRadius,
          "stroke-width": _vm.strokeWidth,
          fill: _vm.white,
          stroke: _vm.grey
        }
      }),
      _vm._v(" "),
      _c("path", {
        style: _vm.arrowTransform,
        attrs: { d: _vm.drawingCommands }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }