var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.classes,
      attrs: {
        type: "button",
        disabled: _vm.disabled,
        "aria-label": "Icon button"
      }
    },
    [
      _vm._t("icon", null, { color: _vm.foregroundColor }),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }